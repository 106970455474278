<template>
    <el-dialog
        width="600px"
        top="40px"
        :append-to-body="true"
        :title="$t('economy.add_item')"
        :visible.sync="modalAddItem"
        :close-on-click-modal="false"
        :before-close="() => $emit('close')"
        @closed="$emit('closed')"
    >
        <el-form
            ref="form"
            v-loading="$wait.is('adding')"
            class="px-12"
            :model="formData"
            :rules="rules"
            label-position="top"
            @submit.native.prevent
            @keyup.native.enter="addItem"
        >
            <el-form-item :label="$t('economy.select_from_existing_services')" prop="title">
                <ServicesDropdown class="w-full" :multiple="false" :withoutQuerySearch="true" @change="preServiceSelected" />
            </el-form-item>

            <el-form-item :label="$t('economy.title')" prop="title">
                <el-input v-model="formData.title" />
            </el-form-item>

            <el-form-item :label="$t('economy.price_type')">
                <el-radio-group v-model="formData.price_type">
                    <el-radio-button label="hourly">
                        {{ $t('economy.hourly') }}
                    </el-radio-button>
                    <el-radio-button label="once">
                        {{ $t('economy.once') }}
                    </el-radio-button>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="RUT">
                <el-switch
                    v-model="formData.rut"
                    active-color="#13ce66"
                    inactive-color="#20a0ff"
                    :active-value="1"
                    :inactive-value="0"
                    :active-text="$t('common.yes')"
                    :inactive-text="$t('common.no')"
                />
            </el-form-item>

            <el-form-item v-if="formData.price_type === 'once'" :label="$t('economy.amount')" prop="amount">
                <el-input v-model="formData.amount" />
            </el-form-item>
            <div v-if="formData.price_type === 'hourly'" class="flex justify-center">
                <el-form-item :label="$t('economy.hours')" prop="hours" class="mr-2">
                    <el-input-number v-model="formData.hours" :precision="0" :min="1" :controls-position="'right'" />
                </el-form-item>
                <el-form-item :label="$t('economy.minutes')" prop="minutes">
                    <el-input-number v-model="formData.minutes" :precision="0" :min="0" :max="45" :step="15" :controls-position="'right'" />
                </el-form-item>
            </div>

            <el-form-item :label="$t('economy.price')" prop="price">
                <money v-model="formData.price" class="el-input__inner" v-bind="money" />
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button :disabled="$wait.is('adding')" @click="$emit('close')">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('adding')" @click="addItem">{{ $t('common.add') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';

Vue.use(Money);

export default {
    components: {
        Money,
        ServicesDropdown: () => import(/* webpackChunkName: "ServicesDropdown" */ '@/components/ServicesDropdown'),
    },

    props: {
        modalAddItem: {
            type:    Boolean,
            default: false,
        },
        invoiceUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            formData: {
                title:      '',
                price_type: 'hourly',
                rut:        0,
                price:      100.00,
                amount:     1,
                hours:      1,
                minutes:    0,
            },
            money: {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            rules: {
                title:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                price:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                hours:   [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                minutes: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                amount:  [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async addItem() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$wait.start('adding');

            let { amount } = this.formData;
            if (this.formData.price_type === 'hourly') {
                amount = (this.formData.hours * 60) + this.formData.minutes;
            }

            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/${this.invoiceUuid}/add_item`, {
                name:       this.formData.title,
                price:      this.formData.price.replace(',', ''),
                price_type: this.formData.price_type,
                count:      amount,
                rut:        this.formData.rut,
            });

            this.$wait.end('adding');
            this.$emit('refresh');
            this.$emit('close');
            this.$notify.success({ title: this.$t('common.success') });
        },

        async preServiceSelected(serviceId) {
            if (!serviceId) return;
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/services/${serviceId}`);

            this.formData.title = data.name;
            this.formData.price_type = data.price_type;
            this.formData.rut = data.rut;
            this.formData.price = data.price / 100;
        },
    },
};
</script>
